import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import {HiOutlineMenuAlt3} from 'react-icons/hi'
import {CgMenuGridR} from 'react-icons/cg'
import {FaMapMarkerAlt} from 'react-icons/fa'
import {BiEnvelope} from 'react-icons/bi'
import {FiSmartphone,FiCamera} from 'react-icons/fi'
import {RiPhoneCameraLine,RiPhoneCameraFill} from 'react-icons/ri'
import {BsArrowLeft,BsArrowRight, BsChevronBarRight, BsChevronLeft, BsChevronRight} from 'react-icons/bs'
import { AllWorked, contact } from '../api/api.ajax'

function Home(props) {
    const lengthchecked=$('input[type="radio"]').length;
    const [count, setcount] = useState(1)
    const [checking,setchecked]= useState('check1')
    const [image,setimage]= useState('1')
    const [menu,setmenu]= useState(false)
    const [now,setnow]= useState('');
    const [loading,setloading]= useState(false);
    const [nom,setnom]= useState('');
    const [email,setemail]= useState('');
    const [sujet,setsujet]= useState('');
    const [message,setmessage]= useState('');
    const [msg,setmsg]= useState('');
    const [bg,setbg]= useState('#00B74A');
    const [galerie,setgallery]= useState('');

    const getgallery = ()=>{
        AllWorked()
        .then((response)=> response.json())
        .then((response)=>{
            if(response.success == 1){
                setgallery(response.msg)
            }else{
                setgallery('')
            }
        })
    }

    if(msg !==''){
        setTimeout(()=>{
            setmsg('')
        },4000)
    }

    const form ={
        nom,
        email,
        sujet,
        message
    }
    
    const onchecked = (check)=>{
        setchecked(check)
    }
    const onChange= (check)=>{
        setimage(check)
        setnow(true)
    }
    const contacts = (e)=>{
        setloading(true)
        e.preventDefault()
        contact(form)
        .then((response)=>{
            setloading(false)
            let data = response.data;
            if(data.success==1){
                setbg('#00B74A')
                setmsg(data.msg)
                setnom('')
                setmessage('')
                setsujet('')
                setemail('')

            }else{
                setmsg(data.msg)
                setbg('#F93154')
            }
        })
    }
    const next = ()=>{
        if(count + 1 > lengthchecked){
            setcount(1)
        }else{
            setcount(count+1)
        }
        onchecked('check'+count)
    }
    const prev = ()=>{
        if(count - 1 < 1){
            setcount(4)
        }else{
            setcount(count-1)
        }
        onchecked('check'+count)
    }
    useEffect(()=>{
    const inte= setInterval(()=>{
            if(count + 1 > lengthchecked){
                setcount(1)
            }else{
                setcount(count+1)
            }
            onchecked('check'+count)
        },15000);

        return ()=>{
            clearInterval(inte)
        }
    })
    const goTo = (id)=>{
        setmenu(!menu)
        window.scrollTo({
            top: document.getElementById(id).offsetTop,
            behavior: "smooth"
        })
        
    }
    useEffect(() => {  
        getgallery()
    }, [])
   
    return (
        <div className="container-fluid">
            <div className={"menu-view animate__animated "+ (menu==true ? 'animate__fadeInDown' : 'animate__fadeOutUp')} style={{zIndex: (menu==true ? 9 : 0)}}>
                <div className="log-menu" >
                    <div className="menu i text_white" style={{textAlign:'right'}} onClick={()=> setmenu(!menu)}>X</div>        
                </div>
                <ul className="ul wow animate__animated animate__fadeInLeft">
                         <li onClick={()=> goTo('home')}  className="wow animate__animated animate__fadeInLeft">Accueil</li>
                         <li onClick={()=> goTo('about')} className="wow animate__animated animate__fadeInLeft">A propos de nous</li>
                         <li onClick={()=> goTo('divers')} className="wow animate__animated animate__fadeInLeft">Nos projets</li>
                         <li onClick={()=> goTo('how')} className="wow animate__animated animate__fadeInLeft">Processus de travail</li>
                         <li onClick={()=> goTo('contact')} className="wow animate__animated animate__fadeInLeft">Contact</li>
                     </ul>
            </div>
               <div className="navbar">
                    <div className="log">
                        <img src="https://api.koema-drc.com/logoko.png" alt="" />
                    </div>
                    <div className="log-menu">
                        <HiOutlineMenuAlt3 className="menu" onClick={()=> setmenu(!menu)}/> 
                    </div>
               </div>
            <div className="container" id="home">
                <div className="finger-print">
                <div className="slid-left" onClick={prev}></div>
                <div className="slid-right" onClick={next}></div>
                </div>
            <ul>
                <li>
                    <div className={checking =='check1' ? 'check active':'check'} onClick={()=> onchecked('check1')}></div>
                    <input type='radio' name="slide" checked={checking =='check1' ? true:false} onChange={()=>{}}/>
                    <div className="slide">
                        <div className="mask"></div>
                        <div className="img-slide" style={{backgroundImage: 'url(https://api.koema-drc.com/le_corbusier.jpg)'}}></div>
                        
                        <div className="content">
                            <p>	« L'architecture est le jeu savant, correct et magnifique, de formes assemblées sous la lumière » </p>
                            <p className="signature">Le Corbusier</p>
                        </div>
                    </div>
                </li>
                <li>
                <div className={checking =='check2' ? 'check active':'check'} onClick={()=> onchecked('check2')}></div>
                    <input type='radio' name="slide" id="check2" checked={checking =='check2' ? true:false} onChange={()=>{}}/>
                    <div className="slide">
                    <div className="mask"></div>
                    <div className="img-slide" style={
                    {backgroundImage: 'url(https://api.koema-drc.com/Bodelaire.jpg)'}}></div>
                       
                        <div className="content">
                        <p>« Là, tout n’est qu’ordre et beauté, Luxe calme et volupté »  </p>
                            <p className="signature"> Baudelaire</p>
                        </div>
                    </div>
                </li>
                <li>
                <div className={checking =='check3' ? 'check active':'check'} onClick={()=> onchecked('check3')}></div>
                    <input type='radio' name="slide" id="check3" checked={checking =='check3' ? true:false} onChange={()=>{}}/>
                    <div className="slide">
                    <div className="mask"></div>
                    <div className="img-slide" style={
                    {backgroundImage: 'url(https://api.koema-drc.com/__Peters.jpg)'}}></div>
                       
                        <div className="content">
                        <p>	« Le design crée la culture. La culture façonne les valeurs. Les valeurs déterminent l'avenir. »  </p>
                            <p className="signature">Robert L. Peters</p>
                        </div>
                    </div>
                </li>
                <li>
                <div className={checking =='check4' ? 'check active':'check'} onClick={()=> onchecked('check4')}></div>
                    <input type='radio' name="slide" id="check4" checked={checking =='check4' ? true:false} onChange={()=>{}}/>
                    <div className="slide">
                    <div className="mask"></div>
                    <div className="img-slide" style={
                    {backgroundImage: 'url(https://api.koema-drc.com/koema.jpg)'}}></div>
                       
                        <div className="content">
                        <p>	« Un espace intérieur qui a du cachet, appartient sans équivoque à une personne qui a du gout »  </p>
                            <p className="signature">KOEMA</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
            <div className="about" id="about">
                <div className="img-about "><div className="img-mask"></div></div>
                <div className="about-content ">
                    <h3 className="wow animate__animated animate__fadeInUp">A PROPOS DE NOUS</h3>
                    <p className="wow animate__animated animate__fadeInUp"> KOEMA Sarlu  est un cabinet d'architecture et de construction installé au Congo Rdc.</p>
                    <p className="wow animate__animated animate__fadeInUp"> Nous intervenons dans tous les domaines de la construction, commençant par le design de votre projet, le choix des materiaux, du mobiliers pour l'amenagement, en passant par le suivis et la réalisation des travaux....</p>
                    <p className="wow animate__animated animate__fadeInUp">À l’écoute de vos besoins et de vos envies, nous étudions les lieux afin de tirer le meilleur parti des espaces disponibles dans le strict respect de votre budget.</p>
                    <p className="wow animate__animated animate__fadeInUp">Valoriser les volumes, travailler les matériaux adaptés, mêler le neuf et l’ancien, jouer avec la lumière : nous repensons la décoration intérieure pour en faire un espace structuré, aux lignes fluides et symétriques avec un résultat contemporain et unique.</p>
                    <p className="wow animate__animated animate__fadeInUp">Chacune de nos réalisations se veut une réponse créative et innovante en parfait accord avec vos souhaits et exigences. </p>

                </div>
            </div>
            <div className="divers" id="divers">
                <div className="contenu">
                    <h1 className="wow animate__animated animate__fadeInUp">NOS PROJETS</h1>
                    <div className="after"></div>
                    <p>Nous mettons l’architecture et le design au service de votre projet et nous transformons les contraintes en opportunités.</p>
                    <div class="grid-container">
                    {
            galerie!='' && galerie.map((item,index)=>
            <div class={"grid-item "+ ((index % 2 == 0) ? 'medium' : 'large') +" wow animate__animated animate__fadeInUp"} style={{backgroundImage:'url(http://admin.koema-drc.com/upload/'+((now == item.id) ? item.prev : item.now)+')'}}>
                <div className="mask-prj"></div>
                
                {
                    item.prev !== null ? <div className="icon-change animate__animated animate__headShake animate__slow animate__infinite">{(now == item.id  ? <BsChevronLeft size={32} onClick={()=> setnow('')}/> : <BsChevronRight size={32} onClick={()=> setnow(item.id)}/>)} </div>: null
                }
                
                
                
                <div className="projet-texte">
                    <p>{item.lieu } <span className="annee">{item.annee}</span>{item.part} </p>
                    <h2>
                        <svg className="text">
                            <symbol id={'text'+item.id}>
                                <text text-anchor="middle" x={item.x} y={'50%'}>{item.description}</text>
                            </symbol>
                            <use href={'#text'+item.id}></use>
                        </svg>
                        </h2>
                </div>
            </div>
            )
                    }
                   
                    </div>
                </div>
                
            </div>
            <div className="how" id="how">
                <div className="how-ombre" style={{backgroundImage:'url(https://api.koema-drc.com/abstract-1867937_1280.jpg)'}}></div>
                <div className="how-contenu ">
                    <h3 className="wow animate__animated animate__fadeInUp">Notre processus de travail</h3>
                    <p className="wow animate__animated animate__fadeInUp">Notre manière de travailler est fragmentée en deux étapes importantes. La première étape est la phase de conception durant laquelle nous allons dessiner le projet selon vos envies. La deuxième étape concerne la maîtrise d’œuvre, englobant tous les aspects techniques et financiers du projet.</p>
                </div>
               <div className="cont">
                   <h3>ETAPE 1 > LA CONCEPTION</h3>
               <div className="cards ">
                    <div className="card-item wow animate__animated animate__fadeInUp">
                        <div className="img-card" style={{backgroundImage:'url(https://mbote.cd/wp-content/uploads/2020/03/525F6699-03FC-4D1C-9916-7FA73B6F061B.jpeg)'}}></div>
                        <div className="card-cont">
                        <h4>#1 PREMIER CONTACT</h4>
                        <h5>Deroulement</h5>
                        <p>Nous nous déplaçons sur les lieux pour prendre connaissance du projet dans ses grandes lignes. Ce premier rendez-vous permet par la suite d'établir un plan d’action pour la suite de nos prestations.</p>
                        </div>
                    </div>
                    <div className="card-item wow animate__animated animate__fadeInUp">
                        <div className="card-cont">
                        <h4>#2 RELEVÉ</h4>
                        <h5>Deroulement</h5>
                        <p>Une fois que notre devis est accepté, nous revenons sur place pour faire un relevé de côtes, prendre des clichés photographiques, établir le cahier des charges, ainsi qu'élaborer le programme de vos envies.</p>
                        </div>
                        <div className="img-card" style={{backgroundImage:'url(https://api.koema-drc.com/proc2.jpeg)'}}></div>

                    </div>
                    <div className="card-item wow animate__animated animate__fadeInUp">
                        <div className="img-card" style={{backgroundImage:'url(https://api.koema-drc.com/proc3.jpeg)'}}></div>
                        <div className="card-cont">
                        <h4>#3 APS</h4>
                        <h5>Deroulement</h5>
                        <p>L’avant-projet Sommaire est la phase la plus importante de la partie conception puisqu'elle consiste à dessiner votre projet grâce à des croquis et/ou Mood board, des visuels 3D et des plans d'aménagement.</p>
                        </div>
                    </div>
                </div>
                <h3>ETAPE 2 > LA MAITRISE D’OEUVRE</h3>
               <div className="cards ">
                    <div className="card-item wow animate__animated animate__fadeInUp">
                        <div className="img-card" style={{backgroundImage:'url(https://api.koema-drc.com/proc4.jpg)'}}></div>
                        <div className="card-cont">
                        <h4>#4 PLANIFICATION DE CONSTRUCTION</h4>
                        <h5>Deroulement</h5>
                        <p>Nous réalisons le devis détaillé des diverses prestations à mettre en œuvre et sélectionnons les éventuels artisans extérieurs supplémentaires, qui vous correspondent.</p>
                        </div>
                    </div>
                    <div className="card-item wow animate__animated animate__fadeInUp">
                        <div className="card-cont">
                        <h4>#5 EXECUTION</h4>
                        <h5>Deroulement</h5>
                        <p> cette phase permet de boucler le projet avec ses détails techniques et ainsi d’élaborer les plans d’exécution pour pouvoir lancer le début des travaux</p>
                        </div>
                        <div className="img-card" style={{backgroundImage:'url(https://api.koema-drc.com/proc5.jpeg)'}}></div>

                    </div>
                    <div className="card-item wow animate__animated animate__fadeInUp">
                        <div className="img-card" style={{backgroundImage:'url(https://api.koema-drc.com/proc6.jpg)'}}></div>
                        <div className="card-cont">
                        <h4>#6 SUIVI DE CHANTIER</h4>
                        <h5>Deroulement</h5>
                        <p>La phase de conception étant terminée et le budget fixé, le début des travaux peut alors commencer. Nous suivons les  chantiers, realisons les travaux et nous nous assurons que les plus petits details soient à la hauteur de vos attentes.</p>
                        </div>
                    </div>
                </div>
               </div>
            </div>
            <div className="contact" id="contact">
                <div className="form-contact wow animate__animated animate__fadeInUp">
                    <h1>CONTACT</h1>
                    <p>Vous avez des questions concernant vos Projets de transformation? Vous souhaitez recevoir un devis ? Pour toute question, n’hésitez pas à nous contacter. Nous nous engageons à vous répondre sous 48h.</p>
                    {
                        msg !='' && <div className="animate__animated animate__bounceIn" style={{Width:'90%',margin:'0 auto',background: bg,padding:'10px 20px',color:'#fff',fontSize:13}}>
                        {msg}

                    </div>
                    }
                    <form action="" method="POST">
                       <div className="form-group">
                           <label htmlFor="nom">Nom complet</label>
                           <input type="text" placeholder="nom complet" onChange={(input)=> setnom(input.target.value)} value={nom}/>
                       </div>
                       <div className="form-group">
                           <label htmlFor="nom">email</label>
                           <input type="email" placeholder="email" onChange={(input)=> setemail(input.target.value)} value={email}/>
                       </div>
                       <div className="form-group">
                           <label htmlFor="nom">sujet</label>
                           <input type="text" placeholder="sujet" onChange={(input)=> setsujet(input.target.value)} value={sujet}/>
                       </div>
                       <div className="form-group">
                           <label htmlFor="nom">Message</label>
                           <textarea placeholder="votre message" onChange={(input)=> setmessage(input.target.value)} value={message}></textarea>
                       </div>
                     <button onClick={contacts}>Envoyer {loading && <img src="https://api.koema-drc.com/ZZ5H.gif" style={{width:10}}/>}</button>
                    </form>
                </div>
            </div>
            <div className="info">
                <div className="info-logo">
                <img src="https://api.koema-drc.com/logoko.png" alt="" className="wow animate_animated animate__bounceIn"/>
                </div>
                <div className="info-info">
                    <h4 className="wow animate__animated animate__fadeInUp">Contact & Info. important</h4>
                    
                    <div className="ii">
                   <div>
                   
                    <ul className="wow animate__animated animate__fadeInUp">
                        <li className="wow animate__animated animate__fadeInUp"><div className="active"><FaMapMarkerAlt /></div> Rond point batetela,<br/> Ref:patachou,<br/> immeuble Crown Tower,<br/> bureau 1002 <br/> Kinshasa/R.D.C</li>
                    </ul>
                   </div>
                    <div>
                   
                    <ul className="wow animate__animated animate__fadeInUp">
                        <li className="wow animate__animated animate__fadeInUp" style={{cursor:'pointer'}} onClick={()=> document.location='mailto:contact@koema-drc.com'}><div className="active"><BiEnvelope /></div> contact@koema-drc.com</li>
                    </ul>
                    </div>
                    <div>
                  
                    <ul className="wow animate__animated animate__fadeInUp">
                        <li className="wow animate__animated animate__fadeInUp"><div className="active"><FiSmartphone /></div> <div> <span style={{cursor:'pointer'}} onClick={()=> document.location='tel:+243813262573'}>+243 81 326 25 75</span><br/><span style={{cursor:'pointer'}} onClick={()=> document.location='tel:+243852224761'}>+243 85 222 47 61</span> </div></li>
                    </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
