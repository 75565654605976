import axios from "axios";
const baseUrl = 'https://api.koema-drc.com/';
export const contact = (form) => {
    let formData = new FormData();
    formData.append('nom', form.nom)
    formData.append('email', form.email)
    formData.append('sujet', form.sujet)
    formData.append('message', form.message)
    return axios({
        method: 'post',
        url: baseUrl + '/REST/contact.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
}

export const AllWorked = () => {
    let elements;
    return fetch(
        baseUrl + 'REST/realisations.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(elements),
        },
    );
}